// import moment from 'moment'

jQuery(document).ready(function ($) {
  // Select elements
  const $featuredCover = $('#featured-cover img');
  const $rightContent = $('.content.right');
  const $leftContent = $('.content.left');
  const $toggleWidget = $('.toggle-widget');

  // Image sources
  const imageOne = './dist/img/cover-ep.png';
  const imageTwo = './dist/img/cover-ep-symphonic.png';

  // Function to swap the image
  const swapImage = () => {
    const currentSrc = $featuredCover.attr('src');
    $featuredCover.attr('src', currentSrc === imageOne ? imageTwo : imageOne);
  };

  // Hover events
  $leftContent.on('mouseenter', () => $featuredCover.attr('src', imageOne));
  $rightContent.on('mouseenter', () => $featuredCover.attr('src', imageTwo));

  // Hovering over featured image swaps to the other one
  $featuredCover.on('mouseenter', swapImage);

  // Toggle switch swaps the image
  $('#switch-container').on('click', function (e) {
    e.preventDefault();
    $('.toggle-widget, .navigation-widget, .blocks-wrapper, .embed-wrapper, .bg-pictures-wrapper').toggleClass('active');
    swapImage();
  });

  // Reset image when leaving content
  $('.content, #featured-cover').on('mouseleave', function () {
    const isReversed = $toggleWidget.hasClass('active');
    $featuredCover.attr('src', isReversed ? imageTwo : imageOne);
  });

  // Smooth scrolling
  function addSmoothScrolling() {
    $('a[href^="#"]').on('click', function (e) {
      e.preventDefault();
      var targetId = $(this).attr("href");
      var offset = 10 * 16;

      if ($(targetId).length) {
        $('html, body').animate(
          { scrollTop: $(targetId).offset().top - offset },
          'slow'
        );
      }
    });
  }

  addSmoothScrolling();
});
